/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-23",
    versionChannel: "nightly",
    buildDate: "2024-06-23T00:06:06.464Z",
    commitHash: "575481f9ccae0bab4bcf6e316128fe5ab2833560",
};
